<template>
  <QuestionnaireBase :title="title" :form="form" :finishFunction="null">
    <template v-slot:default>
      <component :is="show" :key="1" :ref="show" v-model="form"/>
      <component
          v-if="load in $options.components"
          v-show="false"
          :is="load"
          key="load"
          :ref="load"
          v-model="form"
      />
    </template>
  </QuestionnaireBase>
</template>

<script>
import {loadHelpers} from "../loadHelpers";

import QuestionnaireBase from "../../../components/common/questionnaires/questionnaire";
import NameStatusEstatePlanning
  from "../../../components/common/questionnaires/fieldsets/estatePlanning/NameStatusEstatePlanning";
import RelationshipFull from "@/components/common/questionnaires/fieldsets/full/RelationshipFull";
import ContactEstatePlanning
  from "../../../components/common/questionnaires/fieldsets/estatePlanning/ContactEstatePlanning";
import AddressEstatePlanning
  from "../../../components/common/questionnaires/fieldsets/estatePlanning/AddressEstatePlanning";
import ChildrenDependantsEstatePlanning
  from "../../../components/common/questionnaires/fieldsets/estatePlanning/ChildrenDependantsEstatePlanning";
import AssetsEstatePlanning
  from "../../../components/common/questionnaires/fieldsets/estatePlanning/AssetsEstatePlanning";
import PoliciesEstatePlanning
  from "../../../components/common/questionnaires/fieldsets/estatePlanning/PoliciesEstatePlanning";
import LiabilitiesEstatePlanning
  from "../../../components/common/questionnaires/fieldsets/estatePlanning/LiabilitiesEstatePlanning";

export default {
  name: "EstatePlanningFactFind",
  mixins: [loadHelpers],
  components: {
    ContactEstatePlanning,
    NameStatusEstatePlanning,
    RelationshipFull,
    QuestionnaireBase,
    AddressEstatePlanning,
    ChildrenDependantsEstatePlanning,
    AssetsEstatePlanning,
    PoliciesEstatePlanning,
    LiabilitiesEstatePlanning
  },
  data() {
    return {
      name: 'EstatePlanningFactFind',
      title: "Pre-Consultation Questions",
      url: 'fact_find_full',
      order: [ // imported components required here to load
        'NameStatusEstatePlanning',
        'RelationshipFull',
        'ContactEstatePlanning',
        'AddressEstatePlanning',
        'ChildrenDependantsEstatePlanning',
        'AssetsEstatePlanning',
        'PoliciesEstatePlanning',
        'LiabilitiesEstatePlanning'
      ],
      expiryAccessCodeOnFinish: true
    };
  }
};
</script>
